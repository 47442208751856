<template>
  <div
    class="row csn-user-edit csn-user-account-section csn-account-balance"
    id="user-account"
  >
    <div class="col-md-12">
      <div class="container csn-user-profile">
        <section class="csn-account-balance-wrapper">
          <h1 style="color: white;">{{ t('my_account') }}</h1>
          <div class="csn-account-balance-section-1">
            <span>{{ `${t('my_account')}:` }}</span>
            <span>{{ balance }}</span>
          </div>
          <div class="csn-account-balance-section-2">
            <div class="csn-account-balance-section-2-line">
              <span>{{ t('withdrawal_balance') }}</span>
              <span>{{ withdrawableMoney }}</span>
            </div>
            <div class="csn-account-balance-section-2-line">
              <span>{{ t('non_withdrawalable') }}</span>
              <span>{{ nonWithdrawableMoney }}</span>
            </div>
            <div class="csn-account-balance-section-2-line">
              <span>{{ t('bonus_balance') }}</span>
              <span>{{ bonusMoney }}</span>
            </div>
          </div>

          <div
            class="csn-account-balance-section-3"
            v-if="hasBonucCancelOption"
          >
            <span>
              {{ t('attention') }}
            </span>
            <Route :to="{ name: routeName.bonusCancel }">
              {{ t('click_here_to_cancel') }}
            </Route>
          </div>

          <div class="csn-account-balance-section-4">
            <div class="csn-account-balance-section-2-line">
              <span>{{ t('required_turnover') }}</span>
              <span>{{ wager }}</span>
            </div>
            <div class="csn-account-balance-section-2-line">
              <span>{{ t('maximum_conversion') }}</span>
              <span>{{ maxCashout }}</span>
            </div>
          </div>

          <div class="csn-account-balance-section-5">
            <span>{{ t('before_wager') }}</span>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  PROFILE_ACCOUNT_BALANCE,
  Module,
  PERSONAL_INFO,
  RouteName,
  CASINO_INFO,
  Digit,
  EMPTY_OBJECT,
} from '@/constants'
import { formatNumberLocale as format } from '@/helpers'
import * as CasinoInfo from '@/models/getters/casinoInfo'

export default {
  name: PROFILE_ACCOUNT_BALANCE,
  components: {
    Route: () => import('@/components/Route'),
  },
  computed: {
    t() {
      return this.$createComponentTranslator(PROFILE_ACCOUNT_BALANCE)
    },
    ...mapState(Module.USER, [PERSONAL_INFO]),
    ...mapState(Module.CASINO_INFO, [CASINO_INFO]),
    name() {
      return `${this.PERSONAL_INFO.first_name} ${this.PERSONAL_INFO.last_name}`
    },
    routeName() {
      return {
        bonusCancel: RouteName.BONUS_CANCEL,
        deposit: RouteName.DEPOSIT,
        withdrawal: RouteName.WITHDRAWAL,
      }
    },
    balance() {
      return (
        CasinoInfo.getAccountBalance(this.CASINO_INFO) ||
        this.format({ number: Digit.NOUGHT })
      )
    },
    realMoney() {
      return (
        CasinoInfo.getAccountRealMoney(this.CASINO_INFO) ||
        this.format({ number: Digit.NOUGHT })
      )
    },
    bonusMoney() {
      return (
        CasinoInfo.getAccountBonusMoney(this.CASINO_INFO) ||
        this.format({ number: Digit.NOUGHT })
      )
    },
    withdrawableMoney() {
      return (
        CasinoInfo.getAccountWithdrawableMoney(this.CASINO_INFO) ||
        this.format({ number: Digit.NOUGHT })
      )
    },
    nonWithdrawableMoney() {
      return (
        CasinoInfo.getAccountNonWithdrawableMoney(this.CASINO_INFO) ||
        this.format({ number: Digit.NOUGHT })
      )
    },
    temporaryValue() {
      return this.format({ number: Digit.NOUGHT })
    },
    bonusInfo() {
      return CasinoInfo.getBonusInfo(this.CASINO_INFO) || EMPTY_OBJECT
    },
    hasBonucCancelOption() {
      return this.bonusInfo?.isCancellable
    },
    wager() {
      return this.format({ number: this.bonusInfo?.wager || Digit.NOUGHT })
    },
    maxCashout() {
      return this.format({ number: this.bonusInfo?.maxCashout || Digit.NOUGHT })
    },
  },
  methods: {
    format,
  },
}
</script>
